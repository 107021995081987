import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import Cookies from "js-cookie";
import { Slide } from "react-reveal";
import { getCurrentProvider } from "../components/provider";
import Web3 from "web3";
import Swal from "sweetalert2";
import { Dialog } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import BarLoader from "react-bar-loader";
import Popup from "../popup";
import { Helmet } from "react-helmet";
import { Player } from "video-react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ColorRing } from "react-loader-spinner";

import moment from "moment";
const MAX_LENGTH = 300;

export default class afgenesis extends Component {
  constructor(props) {
    super(props);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    this.state = {
      collectionList: [],
      tandc: 0,
      isDialogOpen: false,
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      seoList: "",
      agreed: false,
      setOpen: false,
      itemData: "",
      nfttype: "",
      shippingAddressData: "",
      loaderSpinner: true, // Loader starts as true until data is fetched
      dataFetched: false, // This will track if data is fully fetched
      loaderSpinner1: true, // Loader starts as true until data is fetched
      dataFetched1: false,
      getItemByArtistAndCollectionData: [],
      getItemByArtistAndCollectionDataPhysicalNFT: [],
      collectionId:""
    };
    const {
      match: { params },
    } = this.props;
    this.collectionId = params.collectionId;
  }

  handleCheckboxChange = () => {
    this.setState({ agreed: !this.state.agreed });
  };

  async onOpenModal() {
    this.setState({
      setOpen: true,
    });
  }
  async onCloseModal() {
    this.setState({
      setOpen: false,
      agreed: false,
    });
  }

  componentDidMount() {
    this.getProfilePicAPI();
    this.setState({
      loaderSpinner: true, // Set loader to true initially
      loaderSpinner1: true
    });
    
    setTimeout(() => {
      this.setState({
        loaderSpinner: false, // Set loader to false after 5 seconds
        loaderSpinner1: false
      });
    }, 5000); // 5 seconds in milliseconds
    
    setInterval(() => {
      this.getItemByArtistAndCollectionAction();
      this.getItemByArtistAndCollectionPhysicalNFTAction();
      this.getProfilePicAPI();
    }, 2000);
    setTimeout(() => {
      this.getItemByArtistAndCollectionAction();
      this.getItemByArtistAndCollectionPhysicalNFTAction();
    });
    // this.getItemByArtistAndCollectionAction();
    // this.getItemByArtistAndCollectionPhysicalNFTAction();

    var url = window.location.href;
    var split = url.split("/");
    let lastSecondSegment = split[split.length - 2];
    let lastOneSegment = split[split.length - 1];

    this.getCollectionId(lastOneSegment);
    
    console.log('lastSecondSegment',lastSecondSegment)
    this.getSeoAPI(lastSecondSegment);
    this.getshippingAddressAPI();

    // const receivedData = this.props.location.state;
    // if (receivedData) {
    //   this.collectionId = receivedData.state.collectionId;
    // } else {
    // }
  }
  async getshippingAddressAPI() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}userShippingAddress`,
        data: { userId: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            shippingAddressData: response.data,
          });
        } else {
          this.setState({
            shippingAddressData: "",
          });
        }
      });
    } catch (err) {
      this.setState({
        physicalNftViewData: "",
      });
    }
  }
  async getSeoAPI(lastSecondSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSecondSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => { });
  }

  async getCollectionId(lastOneSegment) {
    this.setState({
      loaderSpinner: true, // Loader starts as true until data is fetched
      loaderSpinner1: true, // Loader starts as true until data is fetched
    })
    await axios({
      method: "post",
      url: `${config.apiUrl}/getcollectionIdByName`,
      data: { short_name: lastOneSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            collectionId: response.data.response.id,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => { });
  }


  

  onChange = (e) => {
    this.setState({
      tandc: e.target.value,
    });
  };

  async getProfilePicAPI() {
    await axios({
      method: "post",
      url: `${config.apiUrl}getuserprofile`,
      headers: { Authorization: this.loginData.Token },
      data: { walletAddress: this.loginData.data?.walletAddress },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          getProfilePicAPIData: response.data?.response,
        });
      }
    });
  }

  async getItemByArtistAndCollectionAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getItemByArtistAndCollection`,
        data: { collectionId: this.state.collectionId },
      }).then((response) => {
        if (response.data.success === true) {
          let _getListItemAPIData = [];

          response.data.data.forEach((item) => {
            let givenTime = new Date(item.datetime);

            // Add 5 hours and 30 minutes
            givenTime.setHours(givenTime.getHours() + item.windowTime);
            givenTime.setMinutes(givenTime.getMinutes());

            // Format the resulting date and time
            let year = givenTime.getFullYear();
            let month = givenTime.getMonth() + 1; // Months are zero-indexed
            let day = givenTime.getDate();
            let hours = givenTime.getHours();
            let minutes = givenTime.getMinutes();
            let seconds = givenTime.getSeconds();

            // Add leading zeros if needed
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            // Display the resulting date and time
            let newDatetimeString =
              year +
              "-" +
              month +
              "-" +
              day +
              " " +
              hours +
              ":" +
              minutes +
              ":" +
              seconds;

            item.newDatetimeString = newDatetimeString;

            // console.log("originalDatetime",originalDatetime)
            // console.log('item.datetime',item.datetime)

            if (item.firstPurchase == 0) {
              _getListItemAPIData.push(item);
            }
          });
          this.setState({
            getItemByArtistAndCollectionData: response.data.data,
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
          // this.setState({
          //   getItemByArtistAndCollectionData: _getListItemAPIData.filter(
          //     (item) =>
          //       (new Date(item.newDatetimeString) < new Date() &&
          //         (this.state.getProfilePicAPIData.forWhiteListedNfts == 0 ||
          //           this.state.getProfilePicAPIData == "")) ||
          //       this.state.getProfilePicAPIData.forWhiteListedNfts == 1
          //   ),
          //   // console.log('new Date(item.newDatetimeString)',new Date()))
          // });
        } else {
          this.setState({
            getItemByArtistAndCollectionData: [],
            dataFetched: true, // Data fetched
          });
        }
      });
    } catch (err) {
      this.setState({
        getItemByArtistAndCollectionData: [],
        dataFetched: true, // Data fetched
      });
    }
  }

  async getItemByArtistAndCollectionPhysicalNFTAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getItemByArtistAndCollectionPhysicalNFT`,
        data: { collectionId: this.state.collectionId },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            getItemByArtistAndCollectionDataPhysicalNFT: response.data.data,
            dataFetched1: true, // Data fetched
          });
        } else {
          this.setState({
            getItemByArtistAndCollectionDataPhysicalNFT: [],
            dataFetched1: true, // Data fetched
          });
        }
      });
    } catch (err) {
      this.setState({
        getItemByArtistAndCollectionDataPhysicalNFT: [],
        dataFetched1: true, // Data fetched
      });
    }
  }

  async mint(e, item, type) {
    e.preventDefault();
    if (this.loginData.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please connect wallet!!",
      });
      return;
    }
    if (type == 2) {
      if (this.state.shippingAddressData?.shippingAddress == "") {
        window.location.href = `${config.baseUrl}setting`;
      }
    }

    this.setState({
      nfttype: type,
    });
    try {
      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);
      var currentNetwork = web3.currentProvider.chainId;
    
      let artistTypeData = item.artistType;
      const requiredChainId =
        artistTypeData == 0 ? config.chainIdTx : config.chainIdSepTx;
      const rpcUrl = artistTypeData == 0 ? config.ethRpcUrl : config.baseRpcUrl;
      if (artistTypeData == 0) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: Web3.utils.toHex(config.chainIdTx),
                    chainName: "ETH",
                    rpcUrls: [`${rpcUrl}`],
                    nativeCurrency: {
                      name: "ETH",
                      symbol: "ETH",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://etherscan.io/"],
                  },
                ],
              });
            } catch (addError) { }
          }
        }
      } else if (artistTypeData == 1) {
        if (currentNetwork != config.chainIdSepTx) {
          try {
            

            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{
                chainId: Web3.utils.toHex(config.chainIdSepTx),

              }],
            });
          } catch (switchError) {
            
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: Web3.utils.toHex(config.chainIdSepTx),
                      chainName: "Base",
                      rpcUrls: [`${rpcUrl}`],
                      nativeCurrency: {
                        name: "ETH",
                        symbol: "ETH",
                        decimals: 18,
                      },
                      blockExplorerUrls: ["https://basescan.org/"],
                    },
                  ],
                });
              } catch (addError) { }
            }
          }
        }

      }
      let userAddress = this.loginData.data.walletAddress;

      this.setState({
        setOpen: true,
        itemData: item,
        nfttype: type,
      });
      if (this.state.agreed == true) {
        await this.redeem(
          rpcUrl,
          item.owner_address,
          item.price,
          item.signature,
          item.contract,
          item.id,
          item.name,
          item.royalty,
          item.sellerWallet,
          item.commission,
          item.bid_increasing_amount,
          item.adminWallet,
          item.secondaryOwner,
          this.state.nfttype,
          item.ImageFileHash,
          artistTypeData
        );
      } else {
        return;
      }
    } catch (error) {
      if (error.message.toString().split("insufficient funds")[1]) {
        this.setState({
          isPopupError: true,
          msg: "Transaction reverted : " + error.message,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      } else {
        if (error.toString().split("execution reverted:")[1]) {
          this.setState({
            isPopupError: true,
            msg:
              "Transaction reverted : " +
              error
                .toString()
                .split("execution reverted:")[1]
                .toString()
                .split("{")[0],
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);

          this.setState({
            isDialogOpen: false,
          });
        } else {
          this.setState({
            isDialogOpen: false,
          });
          this.setState({
            isPopupError: true,
            msg: error.message,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      }
    }
  }

  async redeem(
    rpcUrl,
    owner_address,
    itemprice,
    signature,
    contract,
    itemId,
    itemName,
    royalty,
    sellerWallet,
    commission,
    bid_increasing_amount,
    adminWallet,
    secondaryOwner,
    nfttype,
    ImageFileHash,
    artistTypeData
  ) {
    try {
      const web3 = new Web3(window.ethereum);

      const accounts = await web3.eth.getAccounts();

      let userAddress = accounts[0];
      let balance = await web3.eth.getBalance(userAddress);

      this.setState({
        isDialogOpen: true,
      });

      let artistContractObject = new web3.eth.Contract(
        config.artistAbi,
        contract
      );
      let price = (itemprice * 10 ** 18).toLocaleString("fullwide", {
        useGrouping: false,
      });

      price = parseInt(price);

      let getDiscountPrice = await artistContractObject.methods
        .getDiscountPrice(userAddress, price.toString())
        .call();
      getDiscountPrice = parseInt(getDiscountPrice);

      if (balance < getDiscountPrice) {
        this.setState({
          isDialogOpen: false,
        });
        this.setState({
          isPopupError: true,
          msg: "Insufficient Balance",
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
        return;
      }
      let exprice = parseInt(getDiscountPrice).toString(); //price.toString();
      let royaltyAmount = parseInt(royalty * 10 ** 2).toString();
      let bidIncreasingAmount = parseInt(
        bid_increasing_amount * 10 ** 2
      ).toString();
      let commissionAmount = parseInt(commission * 10 ** 2).toString();

      let physicalContractObject = new web3.eth.Contract(
        config.abiplacePhysicalMarketplace,
        config.marketplacePhysicalContract
      );

      let directMint;

      if (nfttype == 1) {
        directMint = await artistContractObject.methods.directMint(
          exprice,
          royaltyAmount,
          bidIncreasingAmount == "NaN" ? "0" : bidIncreasingAmount,
          commissionAmount,
          sellerWallet === "undefined" || sellerWallet === ""
            ? adminWallet
            : sellerWallet, //commission
          secondaryOwner, //secoundary,
          ImageFileHash,
          1,
          itemId,
          userAddress
          //secoundary
        );
      } else if (nfttype == 2) {
        directMint = await physicalContractObject.methods.purchasePhysicalNft(
          contract,
          itemId,
          exprice,
          royaltyAmount,
          bidIncreasingAmount,
          commissionAmount,
          sellerWallet === "undefined" || sellerWallet === ""
            ? adminWallet
            : sellerWallet, //commission
          secondaryOwner, //secoundary,
          ImageFileHash
        );
      }
      let encoded_tx = directMint.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: nfttype == 1 ? contract : config.marketplacePhysicalContract,
        from: userAddress,
        data: encoded_tx,
        value: exprice,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: nfttype == 1 ? contract : config.marketplacePhysicalContract,
        from: userAddress,
        data: encoded_tx,
        value: exprice,
      });

      if (trx) {
        let balanceOfNFT = await artistContractObject.methods
          .balanceOf(userAddress)
          .call();
        let tokenId = await artistContractObject.methods
          .tokenOfOwnerByIndex(userAddress, parseInt(balanceOfNFT) - 1)
          .call();

        this.setState({
          isDialogOpen: false,
        });
        this.setState({
          isPopupSuccess: true,
          msg: "Nft will be displayed in your profile shortly",
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);

        setTimeout(() => {
          window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
        }, 3500);
        // await this.itemBuyApi(
        //   tokenId,
        //   trx.transactionHash,
        //   itemprice,
        //   itemId,
        //   itemName
        // );
      }
    } catch (err) {
      if (err.message.toString().split("insufficient funds")[1]) {
        this.setState({
          isPopupError: true,
          msg: "Transaction reverted : " + err.message,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      } else {
        if (err.toString().split("execution reverted:")[1]) {
          this.setState({
            isPopupError: true,
            msg:
              "Transaction reverted : " +
              err
                .toString()
                .split("execution reverted:")[1]
                .toString()
                .split("{")[0],
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        } else {
          this.setState({
            isPopupError: true,
            msg: err.message,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      }
      this.setState({
        isDialogOpen: false,
      });
    }
  }

  async itemBuyApi(tokenId, hash, itemprice, itemId, itemName) {
    try {
      let dataa = {
        user_id: this.loginData.data.id,
        buyerAddress: this.loginData.data.walletAddress,
        trx_amount: itemprice,
        item_id: itemId,
        trx_currency: config.currencyType,
        owner: this.loginData.data.first_name,
        amounTrxHash: hash,
        tokenId: tokenId,
        price: itemprice,
        trx_type: 1,
        sell_type: 1,
        cryptoPrice: 0,
        itemName: itemName,
        firstBuyer: this.loginData.data.id,
        owner_id: this.loginData.data.id,
        transfer_hash: hash,
      };
      // return;
      await axios({
        method: "post",
        url: `${config.apiUrl}itemBuy`,
        headers: { Authorization: this.loginData.Token },
        data: dataa,
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            isDialogOpen: false,
          });
          this.setState({
            isPopupSuccess: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);

          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 3500);
        } else {
          this.setState({
            isDialogOpen: false,
          });
          this.setState({
            isPopupError: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      });
    } catch (err) {
      this.setState({
        isDialogOpen: false,
      });
    }
  }

  fetchTokenId(logdecode) {
    for (let i = 2; i < logdecode.length; i++) {
      if (logdecode[i] > 0) {
        return logdecode.toString().substring(i, logdecode.length);
      }
    }
  }
  render() {
    return (
      <>
        <div className="body_bg">
          <Header1 />
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Dialog
            title="Warning"
            icon="warning-sign"
            className="transaction-process"
            style={{
              color: "red",
              zIndex: "1111",
            }}
            isOpen={this.state.isDialogOpen}
            isCloseButtonShown={false}
          >
            <div className="text-center">
              <BarLoader color="#e84747" height="2" />
              <br />
              <h4 style={{ color: "#000" }}>Transaction under process...</h4>
              <p style={{ color: "red" }}>
                Please do not refresh page or close tab.
              </p>
              <div></div>
            </div>
          </Dialog>
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
          />
          {/*---------------------- Afgenesis Section Start -------------------- */}

          <section className="afgenesis pt-lg-5 pt-3 mb-lg-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-4">
                  <Slide left>
                    <div className="card afgenesiscard">
                          {(this.state.loaderSpinner == false && this.state.loaderSpinner1 == false) && (this.state.getItemByArtistAndCollectionData.length ==
                        0 &&
                        this.state.getItemByArtistAndCollectionDataPhysicalNFT
                          .length == 0 )? (
                        <div className="text-center">
                          <div
                            className="d-flex justify-content-center"
                            style={{ marginBottom: "-20px" }}
                          >
                            <dotlottie-player
                              src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json"
                              background="transparent"
                              speed={1}
                              style={{ width: "200px", height: "200px" }}
                              loop
                              autoPlay
                            />
                          </div>
                          <h2>Sorry, no results found!</h2>
                          <h5>No NFTs Found In this Collection</h5>
                        </div>
                      ) 
                       :(this.state.loaderSpinner == true && this.state.loaderSpinner1 == true) || ( this.state.getItemByArtistAndCollectionData.length == 0 && this.state.getItemByArtistAndCollectionDataPhysicalNFT.length == 0)? (
                        <div className="row" >
                          <div className="col-md-12" style={{textAlign:"center"}}>
                        <ColorRing
                          visible={true}
                          height="180"
                          width="180"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={[
                            "#e15b64",
                            "#f47e60",
                            "#f8b26a",
                            "#abbd81",
                            "#849b87",
                          ]}
                        />
                        </div>
                        </div>
                      ) 
                      : (
                        <>
                          {/* Digital NFTs */}
                          {this.state.getItemByArtistAndCollectionData.map(
                            (item) => (
                              <div className="row g-0" key={item.id}>
                                <div className="col-lg-5 col-12 mb-4">
                                      {item.file_type === "image" ? (
                                        
                                        <a
                                        href={
                                          config.imageUrl1 + item.image
                                        }
                                        target="_blank"
                                      >
                                          <div className="cardblock text-center mx-auto my-0 p-3">
                                        <img
                                          className="afgenesisimg img-fluid mn-auto position-relative"
                                          src={config.imageUrl1 + item.image}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                          alt="genesis1"
                                        />
                                        </div>
                                        </a>

                                      ) : item.file_type === "video" ? (
                                        <div className="cardblock text-center mx-auto my-0 p-3">
                                        
                                        <Player
                                          className="marketplace-image"
                                          style={{
                                            height: "350px",
                                            width: "264px",
                                          }}
                                          src={`${config.imageUrl1}${item.image}`}
                                        />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                </div>
                                <div className="col-lg-7 col-12  mb-4">
                                  <div className="card-body px-0 pt-0">
                                    <div className="genesistext pb-3">
                                      <p className="card-text position-relative d-inline-block px-2">Digital Artwork</p>
                                      <h4 className="mb-0">{item.name}</h4>

                                      <p
                                        className="my-3"
                                        style={{ textAlign: "justify" }}
                                        title={item.description}
                                      >
                                        {item.description?.length > MAX_LENGTH
                                          ? `${item.description.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`
                                          : item.description ||
                                          "No description available"}
                                      </p>
                                      <p
                                        style={{ textAlign: "justify" }}
                                        className="mb-4"
                                        title={item.longDescription}
                                      >
                                        {item.longDescription?.length >
                                          MAX_LENGTH
                                          ? `${item.longDescription.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`
                                          : item.longDescription ||
                                          "No description available"}
                                      </p>
                                      <h6>
                                        {item.price}{" "}
                                        {item.artistType == 0
                                          ? config.currencyType
                                          : config.currencyTypeSep}
                                      </h6>
                                    </div>
                                    <div className="genesischeck">
                                      <button
                                        className="custombtn genesis2"
                                        onClick={(e) => this.mint(e, item, 1)}
                                      >
                                        Mint
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          {/* Physical NFTs */}
                          {this.state.getItemByArtistAndCollectionDataPhysicalNFT.map(
                            (item) => (
                              <div className="row g-0" key={item.id}>
                                <div className="col-lg-5 col-12  mb-4">
                                    {item.file_type === "image" ? (
                                      <a
                                        href={
                                          config.imageUrl1 + item.image
                                        }
                                        target="_blank"
                                      >
                                          <div className="cardblock text-center mx-auto my-0 p-3">
                                        <img
                                          className="afgenesisimg img-fluid mn-auto position-relative px-2"
                                          src={config.imageUrl1 + item.image}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                          alt="genesis1"
                                        />
                                      </div>
                                      </a>
                                    ) : item.file_type === "video" ? (
                                      <div className="cardblock text-center mx-auto my-0 p-3">

                                      <Player
                                        className="marketplace-image"
                                        style={{
                                          height: "350px",
                                          width: "264px",
                                        }}
                                        src={`${config.imageUrl1}${item.image}`}
                                      />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                </div>
                                <div className="col-lg-7 col-12  mb-4">
                                  <div className="card-body px-0 pt-0">
                                    <div className="genesistext pb-3">
                                      <p className="card-text position-relative d-inline-block px-2">Physical Artwork</p>
                                      <h4 className="mb-0">{item.name}</h4>

                                      <p
                                        className="my-3"
                                        style={{ textAlign: "justify" }}
                                        title={item.description}
                                      >
                                        {item.description?.length > MAX_LENGTH
                                          ? `${item.description.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`
                                          : item.description ||
                                          "No description available"}
                                      </p>
                                      <p
                                        style={{ textAlign: "justify" }}
                                        className="mb-4"
                                        title={item.longDescription}
                                      >
                                        {item.longDescription?.length >
                                          MAX_LENGTH
                                          ? `${item.longDescription.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`
                                          : item.longDescription ||
                                          "No description available"}
                                      </p>
                                      <h6>
                                        {item.price}{" "}
                                        {item.artistType == 0
                                          ? config.currencyType
                                          : config.currencyTypeSep}
                                      </h6>
                                    </div>
                                    <div className="genesischeck">
                                      <button
                                        className="custombtn genesis2"
                                        onClick={(e) => this.mint(e, item, 2)}
                                      >
                                        Mint
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      )}
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </section>

          <Modal
            open={this.state.setOpen}
            onClose={this.onCloseModal.bind(this)}
            center
          >
            <div className="modal-header mt-4">
              <h5 className="modal-title" id="exampleModalLabel">
                By continuing you agree to our Terms & Conditions.
              </h5>
            </div>

            <div className="modal-body">
              <div className="genesischeck">
                <input
                  className="styled-checkbox"
                  id="styled-checkbox-1"
                  type="checkbox"
                  value={this.state.agreed}
                  onChange={this.handleCheckboxChange}
                />
                {/* <label htmlFor="styled-checkbox-1">I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label> */}
                <label htmlFor="styled-checkbox-1">
                  Agree Terms & Conditions &nbsp; (
                  <a href="https://terms.artfungible.io/" target="_blank">
                    Click Here
                  </a>{" "}
                  &nbsp;to read)
                </label>
              </div>
            </div>

            <div className="modal-footer border-0 footerdiv">
              <button
                type="submit"
                disabled={!this.state.agreed}
                onClick={(e) => {
                  this.mint(e, this.state.itemData, this.state.nfttype);
                }}
                // onClick={this.loginAPIHit.bind(this, this.state.accountStore)}
                className="btn btn-buy-primary px-5"
              >
                Mint{" "}
              </button>
            </div>
          </Modal>

          {/*---------------------- Afgenesis Section End -------------------- */}

          <Footer1 />
        </div>
      </>
    );
  }
}
