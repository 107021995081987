import React, { Component, createRef } from "react";
import config from "../config/config";
import Cookies from "js-cookie";
import axios from "axios";
import Web3 from "web3";
import { getCurrentProvider } from "../components/provider";
import Popup from "../popup";
import Modalone from "../components/modalone";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import { withRouter } from 'react-router-dom';
import { withRouter  } from 'react-router-dom';
import { isMobile } from "mobile-device-detect";
export default class Header1 extends Component {
  constructor(props) {
    super(props);
    this.artistListRef = createRef(); // Create a ref to hold navigate

    window.scrollTo({ top: 0, left: 0 });
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    this.state = {
      ConnectWalletAddress: "",
      getProfilePicAPIData: "",
      searchDataList: [],
      artistList: [],
      headerSettingsForUserData: [],
      lastSegment: "",
      setOpen: false,
      accountStore: "",
      agreed: false,
    };
    this.connectMetasmask = this.connectMetasmask.bind(this);
    this.loginCheck = this.loginCheck.bind(this);
  }
  componentDidMount() {
    const { ethereum } = window;
    setTimeout(() => {
      if (window.ethereum) {
        this.setState({
          ConnectWalletAddress: ethereum.selectedAddress,
        });
      }
    }, 1000);
    setInterval(async () => {
      if (ethereum && this.loginData?.success) {
        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        const account = await web3.eth.getAccounts();

        if (this.state.ConnectWalletAddress == null) {
          this.setState({
            ConnectWalletAddress: account[0],
          });
        }

        if (
          account[0]?.toUpperCase() !==
          this.state.ConnectWalletAddress?.toUpperCase()
        ) {
          Cookies.remove("artfungibleUserloginSuccess");
          // window.location.href = `${config.baseUrl}`;
          this.loginAPI(account[0]);
        }
      }
    }, 2000);

    this.getArtistDetailAction();
    this.headerSettingsForUserAction();

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    this.setState({
      lastSegment: lastSegment,
    });
  }

  handleCheckboxChange = () => {
    this.setState({ agreed: !this.state.agreed });
  };

  async onOpenModal() {
    this.setState({
      setOpen: true,
    });
  }
  async onCloseModal() {
    this.setState({
      setOpen: false,
    });
  }

  async connectMetasmask() {
    if (window.ethereum) {
      var isMetaMask = await window.ethereum.isMetaMask;
      if (!isMetaMask) {
        this.setState({
          isPopupError: true,
          msg: isMobile ? (
            <>
              Open the MetaMask App browser and type{' '}
              <a 
                href="https://www.artfungible.io" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: '#f44336', textDecoration: 'underline' }}
              >
                www.artfungible.io
              </a>{' '}
              in the address bar to connect your wallet.
            </>
          ) : (
            <>
              Please install the{' '}
              <a 
                href="https://metamask.io/" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: '#f44336', textDecoration: 'underline' }}
              >
                Metamask wallet
              </a>{' '}
              to use this App!
            </>
          ),
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);

        return;
      }
      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);
      const accounts = await providers.request({
        method: "eth_requestAccounts",
      });
      const currentChainId = await web3.eth.net.getId();
      console.log('parseInt(currentChainId)',parseInt(currentChainId))
      if (parseInt(currentChainId) != config.chainId) {
        try {
          const data = await web3.currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            this.setState({
              isPopupError: true,
              msg: "Please connect with ETH network",
            });
            setTimeout(() => {
              this.setState({
                isPopupError: false,
              });
            }, 2000);
            return false;
          }
        }
      }
      console.log(parseInt(currentChainId));

      this.loginAPI(accounts[0]);
    } else {
      this.setState({
        isPopupError: true,
        msg: isMobile ? (
          <>
            Open the MetaMask App browser and type{' '}
            <a 
              href="https://www.artfungible.io" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: '#f44336', textDecoration: 'underline' }}
            >
              www.artfungible.io
            </a>{' '}
            in the address bar to connect your wallet.
          </>
        ) : (
          <>
            Please install the{' '}
            <a 
              href="https://metamask.io/" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: '#f44336', textDecoration: 'underline' }}
            >
              Metamask wallet
            </a>{' '}
            to use this App!
          </>
        ),
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    }
  }

  logout() {
    Cookies.remove("artfungibleUserloginSuccess");
    setTimeout(() => {
      window.location.href = `${config.baseUrl}`;
    });
  }

  async loginAPI(account) {
    await axios({
      method: "post",
      url: `${config.apiUrl}addressCheck`,
      data: { walletAddress: account },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          accountStore: account,
        });
        this.loginAPIHit(account);
        // this.setState({
        //   setOpen: true,
        // });
      } else {
        this.loginAPIHit(account);
      }
    });
  }

  async loginAPIHit(account) {
    axios({
      method: "post",
      url: `${config.apiUrl}login`,
      data: { walletAddress: account },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          isPopupSuccess: true,
          msg: response.data.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);

        Cookies.set("artfungibleUserloginSuccess", response.data);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  loginCheck() {
    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else {
      window.location.href = `${config.baseUrl}member`;
    }
  }

  async getArtistDetailAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}getUserArtistDetail`,
      }).then((response) => {
        this.setState({
          artistList: response.data.data.filter(
            (item) => item.isFeatured === 1
          ),
        });
      });
    } catch (err) {}
  }

  async headerSettingsForUserAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}headerSettingsForUser`,
      }).then((response) => {
        this.setState({
          headerSettingsForUserData: response.data.response.filter(
            (item) => item.isActive === 1
          ),
        });
      });
    } catch (err) {}
  }

  artistLink = (item) => {
    const formattedName = item.name.replace(/\s+/g, '-'); // Replace spaces with hyphens
    window.location.href = `${config.baseUrl}${formattedName}`
    Cookies.set('artistId',item.id)
    // this.props.history.push(`${config.baseUrl}${formattedName}`, {
    //   state: { artistId: item.id },
    // });
  };
  


  render() {
    return (
      <>
        <Popup
          isPopupError={this.state.isPopupError}
          isPopupSuccess={this.state.isPopupSuccess}
          msg={this.state.msg}
        />
        {/* <Modalone /> */}

        <header className="">
          <div className="container-fluid px-lg-5">
            {/* <div className="row d-lg-block d-none">
              <div className="col-12 text-center mb-5">
                <a className="navbar-brand mx-auto" href={`${config.baseUrl}`}>
                  <img
                    src="mercafor_dashboard/assets/img/af-logo.svg"
                    style={{ width: "25%" }}
                    className="navbar-logo"
                    alt="logo"
                  />
                  <span className="ml-3">Art Fungible</span>
                </a>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-xl navbar-light">
                  <a className="navbar-brand " href={`${config.baseUrl}`}>
                    <img
                      src="images/mainlogo.svg"
                      className="navbar-logo"
                      alt="logo"
                    />
                    {/* <span className="ml-3">Art Fungible</span> */}
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="menu_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="20"
                        viewBox="0 0 25 20"
                        fill="none"
                      >
                        <path
                          d="M6.03243 0.666667H24.8V3.33333H6.03243V0.666667ZM6.03243 11.3333V8.66667H24.8V11.3333H6.03243ZM2.01081 0C2.54411 0 3.05557 0.210713 3.43267 0.585786C3.80977 0.960859 4.02162 1.46957 4.02162 2C4.02162 2.53043 3.80977 3.03914 3.43267 3.41421C3.05557 3.78929 2.54411 4 2.01081 4C1.47751 4 0.966053 3.78929 0.588953 3.41421C0.211853 3.03914 0 2.53043 0 2C0 1.46957 0.211853 0.960859 0.588953 0.585786C0.966053 0.210713 1.47751 0 2.01081 0ZM2.01081 8C2.54411 8 3.05557 8.21071 3.43267 8.58579C3.80977 8.96086 4.02162 9.46957 4.02162 10C4.02162 10.5304 3.80977 11.0391 3.43267 11.4142C3.05557 11.7893 2.54411 12 2.01081 12C1.47751 12 0.966053 11.7893 0.588953 11.4142C0.211853 11.0391 0 10.5304 0 10C0 9.46957 0.211853 8.96086 0.588953 8.58579C0.966053 8.21071 1.47751 8 2.01081 8ZM6.03243 19.3333V16.6667H24.8V19.3333H6.03243ZM2.01081 16C2.54411 16 3.05557 16.2107 3.43267 16.5858C3.80977 16.9609 4.02162 17.4696 4.02162 18C4.02162 18.5304 3.80977 19.0391 3.43267 19.4142C3.05557 19.7893 2.54411 20 2.01081 20C1.47751 20 0.966053 19.7893 0.588953 19.4142C0.211853 19.0391 0 18.5304 0 18C0 17.4696 0.211853 16.9609 0.588953 16.5858C0.966053 16.2107 1.47751 16 2.01081 16Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>

                  {/* <a className="navbar-brand" href="#" >
                  <img
                      src="mercafor_dashboard/assets/img/af-logo.png"
                      style={{ width: "25%" }}
                      className="navbar-logo"
                      alt="logo"
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button> */}

                  <div
                    className="collapse navbar-collapse main-menu-item "
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mx-auto">
                      <li
                        className={`nav-item ${
                          this.state.lastSegment === "marketplaceNew"
                            ? "active"
                            : ""
                        } `}
                      >
                        <a
                          className="nav-link"
                          href={`${config.baseUrl}marketplaceNew`}
                        >
                          Marketplace
                        </a>
                      </li>
                      <li
                        className={`nav-item ${
                          this.state.lastSegment === "member" ? "active" : ""
                        } `}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          className="nav-link"
                          onClick={this.loginCheck.bind(this)}
                        >
                          Members
                        </span>
                      </li>
                      <li className="nav-item ">
                        <div className="dropdown navdrop">
                          <button className="dropbtn">
                            Artists
                            <span>
                              <img
                                src="mercafor_dashboard/assets/img/downarrow.svg"
                                style={{ width: "12px" }}
                                className=""
                                alt="downarrow"
                              />
                            </span>
                          </button>
                          <div className="dropdown-content box-shadow-none border-0 py-3 bg-transparent px-0 backdrop-filter-none">
                          <div className="dropdown-box">
  {this.state.artistList.map((item) => (
    <a key={item.id} onClick={() => this.artistLink(item)} href="javascript:void(0)">
      {item.name}
    </a>
  ))}
</div>

                          </div>
                        </div>
                      </li>
                      <li
                        className={`nav-item ${
                          this.state.lastSegment === "marketplaceNew"
                            ? "active"
                            : ""
                        } `}
                      >
                        <div className="dropdown navdrop">
                          <button className="dropbtn">
                            Resources
                            <span>
                              <img
                                src="mercafor_dashboard/assets/img/downarrow.svg"
                                style={{ width: "12px" }}
                                className=""
                                alt="downarrow"
                              />
                            </span>
                          </button>
                          <div className="dropdown-content box-shadow-none border-0 py-3 bg-transparent backdrop-filter-none">
                            <div className="dropdown-box">
                              <a
                                href={`https://help.artfungible.io/`}
                                target="_blank"
                              >
                                Doc
                              </a>
                              <a href={`${config.baseUrl}blog`} target="_blank">
                                Blog
                              </a>
                              <a
                                href={`https://help.artfungible.io/`}
                                target="_blank"
                              >
                                Roadmap
                              </a>
                              <a
                                href={`${config.baseUrl}whyArtFungible`}
                                target="_blank"
                              >
                                Why Art Fungible
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* <li
                        className={`nav-item ${
                          this.state.lastSegment === "marketplaceNew"
                            ? "active"
                            : ""
                        } `}
                      >
                        <a
                          className="nav-link"
                          href={`${config.baseUrl}marketplaceNew`}
                        >
                          Resources
                        </a>
                      </li> */}

                      {/* {this.state.headerSettingsForUserData.map((item) => (
                          <li className="nav-item" key={item.id}>
                            <a
                              className="nav-link"
                              href={item.url}
                              target="_blank"
                            >
                              {item.sections}
                            </a>
                          </li>
                        ))} */}
                    </ul>

                    <div
                      className=" pt-lg-0 pt-3 logoutbtn"
                      // style={{ paddingLeft: "230px" }}
                    >
                      {this.loginData.length === 0 ? (
                        <button
                          className=" btn btn-light-success cursor px-4"
                          // data-toggle="modal"
                          // data-target="#exampleModal"
                          onClick={(e) => this.connectMetasmask()}
                        >
                          Connect Wallet
                        </button>
                      ) : (
                        <>
                          <button
                            style={{
                              background:
                                "var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%))",
                            }}
                            className="custombtn cursor border-0"
                          >
                            {this.state.ConnectWalletAddress?.substring(0, 6) +
                              "...." +
                              this.state.ConnectWalletAddress?.substring(
                                this.state.ConnectWalletAddress?.length - 6
                              )}
                          </button>
                          <div className="fprofilemenu">
                            <ul className="navbar-nav">
                              <li
                                className={`nav-item ${
                                  this.state.lastSegment ===
                                  this.loginData?.data?.walletAddress
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <a
                                  className="nav-link"
                                  href={`${config.baseUrl}membership/${this.loginData?.data?.walletAddress}`}
                                >
                                  Profile
                                </a>
                              </li>
                              <li className="nav-item">
                                <span
                                  className="nav-link cursor"
                                  onClick={this.logout}
                                >
                                  Logout
                                </span>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <Modal
          open={this.state.setOpen}
          onClose={this.onCloseModal.bind(this)}
          center
        >
          <div className="modal-header mt-4">
            <h5 className="modal-title" id="exampleModalLabel">
              By continuing you agree to our Terms & Conditions.
            </h5>
          </div>

          <div className="modal-body">
            <div className="genesischeck">
              <input
                className="styled-checkbox"
                id="styled-checkbox-1"
                type="checkbox"
                value={this.state.agreed}
                onChange={this.handleCheckboxChange}
              />
              {/* <label htmlFor="styled-checkbox-1">I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label> */}
              <label htmlFor="styled-checkbox-1">
                Agree Terms & Conditions &nbsp; (
                <a href="https://terms.artfungible.io/" target="_blank">
                  Click Here
                </a>{" "}
                &nbsp;to read)
              </label>
            </div>
          </div>

          <div className="modal-footer border-0 footerdiv">
            <button
              type="submit"
              disabled={!this.state.agreed}
              onClick={this.loginAPIHit.bind(this, this.state.accountStore)}
              className="btn btn-buy-primary px-5"
            >
              Login{" "}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
